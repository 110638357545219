import React, { Fragment, useState } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { SRLWrapper } from "simple-react-lightbox";
import { FaArrowLeft, FaChevronLeft, FaChevronUp } from 'react-icons/fa';
const { htmlToText } = require('html-to-text');

export default function SubCat({ data }) {

  const page = data.allWpPortfolioCategory
  const [readMore,setReadMore] = useState(false);
  const options = {
    buttons: {
      showDownloadButton: false,
    }
  }

  return (
    <Layout>
      <Seo 
        title={`${page.nodes[0].name} | ${page.nodes[0].wpParent.node.name}`}
        description={page.nodes[0].description}
        image={page.nodes[0].portfolioItems.nodes[0].extra.gallery[0].mediaItemUrl}
      />
      <Fragment>
      <div className="ptb--30">
      {page.nodes.map((node, i) => (
        <div key={i}>
          <div>
            <div className="row ptb--30">
              <div style={{width:'auto'}}>
                <button style={{backgroundColor:'transparent',border:'none',textAlign:'left',paddingBottom:'10px'}} onClick={() => navigate(-1)}>
                  <FaArrowLeft/>
                </button>
              </div>
              <h1 style={{marginBottom:'0',width:'auto',position:'absolute',left:'50%',transform:'translateX(-50%)'}}>
              {node.name}
              </h1>
            </div>
          {node.extra.shortDescription ?
          <Fragment>
            {readMore ?
            <Fragment>
              <div className="noborder" dangerouslySetInnerHTML={{ __html: node.extra.fullDescription }}/>
              <div>
                <FaChevronUp style={{opacity:'.6',fontSize:'10px'}}/>&nbsp;<button className="readmore" onClick={()=>{setReadMore(!readMore)}}>Read Less...</button>
              </div>
            </Fragment>
            :
            <Fragment>
              <div>
              <div className="noborder" dangerouslySetInnerHTML={{ __html: node.extra.shortDescription }} />
              <div>
                <FaChevronLeft style={{opacity:'.6',fontSize:'10px'}}/>&nbsp;<button className="readmore" onClick={()=>{setReadMore(!readMore)}}>Read More...</button>
              </div>
              </div>
            </Fragment>
            }
          </Fragment>
          :
            <p className="pb--50">{node.description}</p>
          }
          </div>
          <SRLWrapper options={options}>
            {node.portfolioItems.nodes.filter(x => x.extra.hide === null).map((node, i) =>(
                <GatsbyImage
                  key={i}
                  className="col-12 col-md-3"
                  style={{margin:'0 auto',cursor:'pointer',objectFit:'cover',height:'200px',boxSizing:'border-box',border:"1px solid white"}}
                  image={node.extra.gallery[0].localFile.childImageSharp.gatsbyImageData}
                  alt={node.content ? htmlToText(node.content) : node.title}
                />
            ))}
          </SRLWrapper>
        </div>
      ))}
      </div>
      </Fragment>
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    allWpPortfolioCategory(filter: {slug: {eq: $slug}}) {
      nodes {
        slug
        name
        description
        wpParent {
          node {
            slug
            name
          }
        }
        extra {
          shortDescription
          fullDescription
          height
          width
          thumbnail {
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR, formats: AUTO)
              }
            }
          }
        }
        portfolioItems {
          nodes {
            title
            slug
            uri
            content
            extra{
              hide
              gallery{
                mediaItemUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR, formats: AUTO)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
